<template>
  <v-app-bar app clipped-left clipped-right color="primary" dark>
    <v-toolbar-title class="align-center d-flex">
      <span class="logo-icon">
        <!-- <img src="../../assets/abbLabel.jpg" width="170px" height="50px" class="mt-2" /> -->
        <img src="../../assets/logo-header.png" width="150px" height="50px" class="mt-2" />
      </span>
      <!-- <span class="logo-text ml-2">
        <img src="../../assets/abbLabel.jpg" class="mt-2" />
      </span> -->
      <!-- <span class="logo-text ml-2">
        ABB LABELS
      </span> -->
    </v-toolbar-title>
    <v-app-bar-nav-icon
        @click="$vuetify.breakpoint.smAndDown ? setSidebarDrawer(!Sidebar_drawer) : $emit('input', !value)"
        class="hidden-sm-and-down"
        
      ></v-app-bar-nav-icon>
    <v-app-bar-nav-icon
      class="d-block d-md-none"
      @click="$vuetify.breakpoint.smAndDown ? setSidebarDrawer(!Sidebar_drawer) : $emit('input', !value)"
    />
    <v-spacer />
    
    <!-- <v-menu bottom left transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in userprofile" :key="i" @click="href">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
    <v-toolbar-items class="nav_item hidden-sm-and-down">
        <v-row justify="center">
          <v-menu
            :close-on-content-click="true"
            :nudge-bottom="40"
            :nudge-right="50"
            transition="slide-y-transition"
            style="padding-top:40px;"
            bottom
            dark
          >
            <template v-slot:activator="{ on }">
              <!-- <v-btn
                text
                :ripple="false"
                class="nav_item"
                depressed
                v-on="on"
              ><v-avatar  >
          <img src="https://randomuser.me/api/portraits/men/81.jpg" />
        </v-avatar></v-btn> -->

             <v-list-item-avatar color="grey"  v-on="on" style="cursor:pointer;">
          <img src="../../assets/user.png" />
        </v-list-item-avatar>
            </template>
            <v-card max-width="800">
              <v-card-title >
                <v-layout justify-center v-if="userData">
                  {{userData.firstName}} {{userData.lastName}} ({{userData.agentCode}})
                </v-layout>
               
                 

              </v-card-title>
              <v-card-subtitle v-if="userData">{{userData.email}}</v-card-subtitle>
              <v-layout pa-3>
                <v-flex>
                  
                    <v-layout  style="cursor:pointer;">
                      <v-flex>
                        <span style="font-size:12px;color:white">Account Settings</span>
                      </v-flex>
                      <v-flex pl-3>
                        <v-icon >mdi-settings</v-icon>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                  <v-layout @click="appSettings" style="cursor:pointer;">
                      <v-flex>
                        <span style="font-size:12px;color:white">Change Password</span>
                      </v-flex>
                      <v-flex pl-3>
                        <v-icon >mdi-settings</v-icon>
                      </v-flex>
                    </v-layout>
                  <v-divider></v-divider>
                  <v-layout @click="appLogout" style="cursor:pointer;">
                    <v-flex text-left>
                      <span style="font-size:12px;">Sign Out</span>
                    </v-flex>
                    <v-flex text-right>
                      <v-icon style="font-size:14px;">mdi-logout</v-icon>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-menu>
          <v-spacer></v-spacer>
        </v-row>
      </v-toolbar-items>
  </v-app-bar>
</template>
<script>
// Utilities
import store from "../../store";
import axios from "axios";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Header",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    userData:null,
    userprofile: [
      { title: "My Profile" },
      { title: "My Balance" },
      { title: "Inbox" },
      { title: "Account Setting" },
      { title: "Logout" }
    ],
    href() {
      return undefined;
    }
  }),

  computed: {
    ...mapState(["Sidebar_drawer"])
  },
   beforeMount(){
    this.getProfile()
  },
  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER"
    }),
    getProfile() {
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            
            this.userData = response.data.data;
           
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    appLogout() {
    
       if (store.state.isLoggedIn) {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("agentCode")
        store.commit("logoutUser");
        this.$router.push({ path: "/Login" });
      }
    
    },
    appSettings() {
        this.$router.push({ path: "/Changepassword" });
    
    },
  },
  
};
</script>

<style >
.router-link{
    text-decoration: none;
}
</style>